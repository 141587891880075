<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null && confirmationElectronic">
          <v-card-title>
            Confirmation Yes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="confirmationElectronic"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>
      </div>
      <div class="col-md-12">
        <v-card v-if="selected == null && noconfirmationElectronic">
          <v-card-title>
            Confirmation No
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="noconfirmationElectronic"
            :search="search2"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">

          <OrderInfo :idOrder="selected.id_cleaning"></OrderInfo>

          <b-card class="mt-3" header="Payment">
              <b-form-file
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Back</b-button>
              <b-button type="button" @click="files" variant="primary">Pay</b-button>
          </b-card>

          <b-card class="mt-3" header="Homes Info" v-if="selected.home">
            <div>
              <b-table striped hover :items="selected.home" :fields="fields"></b-table>
            </div>
          </b-card>

          <b-card class="mt-3" header="Offices Info" v-if="selected.office">
            <div>
              <b-table striped hover :items="selected.office" :fields="fields"></b-table>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrderInfo from "@/view/content/OrderInfo.vue";
import axios from 'axios';

export default {
  name: "inspection-done",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },
    files() {
      const fd = new FormData();
      fd.append("file", this.file);
      fd.append("wid", this.selected.id);
      fd.append("jid", this.selected.id_cleaning);
      axios
        .post("https://cleancity.krueltech.com/dev/api/upload-pay.php", fd)
        .then(res => {
          alert('Payment Successfully');
          window.location.reload();
          console.log(res);
        });
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  components: {
    OrderInfo,
  },
  data() {
    return {
      fields: ["name", "city", "address", "type"],
      file: null,
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "Order ID", value: "id_cleaning" },
        { text: "Independent Contractor", value: "name_worker" },
        { text: "Customer", value: "name_client" },
        { text: "Home/Office", value: "name_house" },
        { text: "Address", value: "address" }
      ],
      confirmationElectronic: null,
      noconfirmationElectronic: null
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readConfimartionDone")
      .then(res => res.json())
      .then(data => {
        this.confirmationElectronic = data.confirmationElectronic;
        this.noconfirmationElectronic = data.noconfirmationElectronic;
      })
      .catch(console.log);
  }
};
</script>
